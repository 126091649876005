export const homeConfig = {
  "@context": "https://schema.org/",
  "@graph": [
    {
      "@type": "Store",
      "@context": "https://schema.org",
      // image: storeConfig?.frontend_config_logo,
      paymentAccepted: [
        "http://purl.org/goodrelations/v1#Cash",
        "http://purl.org/goodrelations/v1#ByBankTransferInAdvance",
      ],
      currenciesAccepted: "USD",
      founder: {
        "@type": "Person",
        //   "@id": `${process.env.NEXT_PUBLIC_URL}/#person`,
        name: "James Ginny",
        sameAs: ["https://x.com/GinnyJames112"],
        knowsLanguage: ["English"],
        description: "",
        jobTitle: "Founder",
        gender: "https://schema.org/Male",
        image: `${process.env.NEXT_PUBLIC_BE_URI}/media/catalog/category/Ginny_James.jpg`,
      },
      employee: {
        "@id": `${process.env.NEXT_PUBLIC_URL}/#person`,
      },
      publicAccess: "true",
      latitude: 39.1573471,
      longitude: -75.5242788,
      openingHoursSpecification: [
        {
          "@type": "OpeningHoursSpecification",
          opens: "07:00:00",
          closes: "23:00:00",
          dayOfWeek: "https://schema.org/Monday",
        },
        {
          "@type": "OpeningHoursSpecification",
          closes: "23:00:00",
          dayOfWeek: "https://schema.org/Tuesday",
          opens: "07:00:00",
        },
        {
          "@type": "OpeningHoursSpecification",
          closes: "23:00:00",
          dayOfWeek: "https://schema.org/Wednesday",
          opens: "07:00:00",
        },
        {
          "@type": "OpeningHoursSpecification",
          closes: "23:00:00",
          dayOfWeek: "https://schema.org/Thursday",
          opens: "07:00:00",
        },
        {
          "@type": "OpeningHoursSpecification",
          closes: "23:00:00",
          dayOfWeek: "https://schema.org/Friday",
          opens: "07:00:00",
        },
        {
          "@type": "OpeningHoursSpecification",
          closes: "23:00:00",
          dayOfWeek: "https://schema.org/Saturday",
          opens: "07:00:00",
        },
        {
          "@type": "OpeningHoursSpecification",
          closes: "23:00:00",
          dayOfWeek: "https://schema.org/Sunday",
          opens: "07:00:00",
        },
      ],
      sameAs: [],
      url: process.env.NEXT_PUBLIC_URL,
      "@id": `${process.env.NEXT_PUBLIC_URL}/#organization`,
      potentialAction: {
        result: {
          "@type": "Reservation",
          name: "Checkout",
        },
        "@type": "ConsumeAction",
        target: [
          {
            "@type": "EntryPoint",
            inLanguage: "en",
            actionPlatform: [
              "http://schema.org/DesktopWebPlatform",
              "http://schema.org/IOSPlatform",
              "http://schema.org/AndroidPlatform",
            ],
            urlTemplate: `${process.env.NEXT_PUBLIC_URL}/carts`,
          },
        ],
      },
      // name: title,
      alternatename: process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
      // description: pageMeta?.meta_description,
      disambiguatingdescription:
        process.env.NEXT_PUBLIC_SEO_DEFAULT_DISAMBIGUATING,
      // logo: storeConfig?.frontend_config_logo,
      // slogan:
      //   pageMeta?.meta_title || process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE,
      priceRange: "23.99USD - 32.99USD",
      hasMap: ["https://maps.app.goo.gl/j4rzLANNjadfiLRUA"],
      mainEntityOfPage: process.env.NEXT_PUBLIC_URL,
      // email: storeConfig?.tracking_support_email,
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "3022615295",
        contactType: "Customer support",
        //   email: storeConfig?.tracking_support_email,
      },
      telephone: "3022615295",
      address: {
        "@type": "PostalAddress",
        addressCountry: "US",
        addressLocality: "Dover",
        addressRegion: "Delaware",
        streetAddress: "8 The Green A",
        postalCode: "19901",
      },
      foundingLocation: {
        "@type": "Place",
        name: "Dover",
        url: "https://en.wikipedia.org/wiki/Dover,_Delaware",
        address: "United States",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 39.15755921345033,
        longitude: -75.52350632697237,
      },
      legalName: "",
      foundingDate: "2024-02-21",
      numberOfEmployees: "100",
      hasOfferCatalog: {
        "@type": "OfferCatalog",
        itemListElement: [
          {
            "@type": "Offer",
            name: "Clothing",
            itemOffered: `${process.env.NEXT_PUBLIC_URL}/categories/clothing`,
            url: `${process.env.NEXT_PUBLIC_URL}/categories/clothing`,
          },
          {
            "@type": "Offer",
            name: "Accessories",
            itemOffered: `${process.env.NEXT_PUBLIC_URL}/categories/accessories`,
            url: `${process.env.NEXT_PUBLIC_URL}/categories/accessories`,
          },
        ],
      },
      areaServed: {
        "@type": "Country",
        url: "https://www.wikidata.org/wiki/Q30",
        name: "United States of America",
      },
      alumni: [
        {
          "@id": `${process.env.NEXT_PUBLIC_URL}/#person`,
        },
      ],
      sponsor: [
        {
          "@id": `${process.env.NEXT_PUBLIC_URL}/#person`,
        },
      ],
      brand: {
        "@type": "Organization",
        name: process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
        url: process.env.NEXT_PUBLIC_URL,
        //   logo: storeConfig?.frontend_config_logo,
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "3022615295",
          contactType: "Customer Service",
          // email: storeConfig?.tracking_support_email,
          areaServed: "United States of America",
        },
        location: {
          "@type": "PostalAddress",
          addressCountry: "USA",
          // name: title,
          addressLocality: "Dover",
          addressRegion: "Delaware",
          streetAddress: "8 The Green A",
          postalCode: "19901",
        },
      },
    },
  ],
};
