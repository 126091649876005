import Head from "next/head";
import { homeConfig as defaultHomeConfig } from "~/schema-config";
import { StoreConfig } from "~/services/store-config";
import { getUrlByTheme, getUrlCustom } from "~/utils/theme";

type Props = {
  title: string;
  storeConfig: StoreConfig;
  pageMeta?: Partial<{
    meta_description: string;
    meta_title: string;
    meta_keywords: string;
  }>;
};

const HomeSchema = ({ title, storeConfig, pageMeta }: Props) => {
  const getSChemaConfig = () => {
    try {
      const {
        homeConfig,
      } = require(`~/theme/${process.env.NEXT_PUBLIC_THEME}/schema-config`);

      return homeConfig ?? defaultHomeConfig;
    } catch (error) {
      return defaultHomeConfig;
    }
  };
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(getSChemaConfig()) }}
      >
        {}
      </script>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: getUrlByTheme,
            potentialAction: {
              "@type": "SearchAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate: `${process.env.NEXT_PUBLIC_URL}/search?keyword={search_term_string}&utm_campaign=action_schema_markup`,
              },
              "query-input": "required name=search_term_string",
            },
          }),
        }}
      ></script>
    </Head>
  );
};

export default HomeSchema;
